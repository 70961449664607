import { createRouter, createWebHashHistory, createWebHistory, onBeforeRouteUpdate } from 'vue-router'
import { ElMessageBox } from 'element-plus'
import i18n from '@/i18n'
import { useUserStore } from '@/stores/user'
import { config } from '@/config'
import NProgress from 'nprogress'
import { useAppStore } from '@/stores/app'
import { asyncRoutes, platform } from './asyncRoutes'
import { constantRoutes } from './constantRoutes'
import { cloneDeep } from 'lodash-es'
import { ref } from 'vue'
import { getBrowserTitle } from '@/utils/env'

const router = createRouter({
  history: config.localMode
    ? createWebHashHistory(import.meta.env.BASE_URL)
    : createWebHistory(import.meta.env.BASE_URL),
  routes: [],
})
router.config = ref([])

/**
 * 检查路由是否已开放
 * @param r
 * @returns {boolean}
 */
function checkRouterIsPublic(r) {
  if (r.meta === undefined) {
    return true
  }
  if (r.meta.public === undefined) {
    return true
  }
  const p = r.meta.public

  if (platform === 'desktop') {
    if (p.desktop === undefined) {
      return true
    }
    return p.desktop
  } else if (platform === 'mobile') {
    if (p.mobile === undefined) {
      return true
    }
    return p.mobile
  }
  return false
}

const comps = import.meta.glob('/src/ui/**/*.*')

/**
 * 加载Route的配置
 */
function loadRouteConfig(routerConfig) {
  if (!routerConfig) return

  routerConfig.forEach((route) => {
    if (tryLoadRouteGroup(route, '')) {
      router.config.value.push(route)
      router.addRoute(route)
    }
  })

  // 检查是否有重复name的路由
  const sames = []
  router.config.value.forEach((route) => {
    checkRouteSameName(route, sames, [])
  })

  if (Object.keys(sames).length > 0) {
    console.error('路由配置name重复. ' + sames.join(', '))
    ElMessageBox.alert(sames.join(', '), '路由配置name重复', {
      showClose: false,
      confirmButtonText: 'OK',
      callback: () => {},
    })
  }
}

function checkRouteSameName(route, sames, names) {
  if (names.includes(route.name)) {
    if (!sames.includes(route.name)) {
      sames.push(route.name)
    }
  } else {
    names.push(route.name)
  }

  if (route.children) {
    route.children.forEach((route) => {
      checkRouteSameName(route, sames, names)
    })
  }
}

/**
 * 加载路由组
 * @param route 路由
 * @param parentPath 上级路由路径
 */
function tryLoadRouteGroup(route, parentPath = '') {
  if (!route.path) {
    route.path = route.name
  }

  if (parentPath) {
    if (parentPath === '/') {
      route.fullPath = parentPath + route.path
    } else {
      route.fullPath = parentPath + '/' + route.path
    }
  } else {
    route.fullPath = route.path
  }

  if (!checkRouterIsPublic(route)) {
    return false
  }

  let canLoad = false
  if (route.children === undefined) {
    canLoad = true
  } else {
    // 检查子路由
    let index = -1
    while (index + 1 < route.children.length) {
      const child = route.children[++index]
      if (!tryLoadRouteGroup(child, route.fullPath)) {
        route.children.splice(index--, 1)
        continue
      }
      canLoad = true
    }
  }
  if (canLoad) {
    if (typeof route.component === 'string') {
      if (!route.meta) {
        route.meta = {}
      }
      route.meta.componentPath = route.component.replace('${platform}', platform)
      route.component = comps[route.meta.componentPath]
      // console.log(route.fullPath, comps[route.meta.componentPath])
    }
  }

  return canLoad
}

/**
 * 根据手机还是PC, 重新加载路由
 */
const reloadRoutesHandler = function (asyncRoutesConfig) {
  console.log('重新加载路由', asyncRoutesConfig)
  asyncRoutesConfig.forEach((item) => {
    if (!item.meta) {
      item.meta = {
        needLogin: true,
      }
    }
    if (item.meta.needLogin === undefined) {
      item.meta.needLogin = true
    }
  })

  // console.error('asyncRoutesConfig', asyncRoutesConfig)
  // 清空路由
  router.getRoutes().forEach((route) => {
    const name = route.name
    if (name) {
      router.removeRoute(name)
    }
  })
  router.config.value.length = 0
  console.log('router.config.value 清空路由')

  // console.log('重新加载Router配置')

  // 动态路由
  const layoutIndex = constantRoutes.findIndex((route) => route.name === '/')
  constantRoutes[layoutIndex].children = asyncRoutesConfig
  const refreshRouteIndex = constantRoutes[layoutIndex].children.findIndex((route) => route.name === 'refresh')
  if (refreshRouteIndex === -1) {
    constantRoutes[layoutIndex].children.push({
      path: 'refresh',
      name: 'refresh',
      component: () => import(/* @vite-ignore */ `/src/ui/base/refresh.vue`),
      meta: {
        title: 'refresh',
        hidden: true,
        needLogin: false,
      },
    })
  }

  // 加载路径
  loadRouteConfig(constantRoutes)
}

router.reloadRoutes = function (isForceReload) {
  // console.log('router.config.value.length', router.config.value.length)
  if (!isForceReload && router.config.value.length > 0) {
    return
  }

  if (import.meta.env.VITE_IS_SERVER_ROUTE === 'true') {
    // -1未登录；0已登录；1登录失效；
    let localStorageUserStr = localStorage.getItem('user')
    const localStorageUser = localStorageUserStr ? JSON.parse(localStorageUserStr) : { routes: [] }
    let async_routers = []
    try {
      if (useUserStore().routes.length > 0) {
        async_routers = cloneDeep(useUserStore().routes)
      }
    } catch (e) {
      console.error(e)
      try {
        async_routers = localStorageUser.routes
        console.log('!!!!!!!!!!', async_routers)
      } catch {
        async_routers = []
      }
    }

    reloadRoutesHandler(async_routers)
  } else {
    localStorage.setItem('router_model', 'local')
    reloadRoutesHandler(asyncRoutes)
  }

  // console.warn('router.getRoutes', router.getRoutes())
  // console.warn('router.config', router.config.value)
}

// router.reloadRoutes(false)

/** 检查路由是否存在 */
function checkRouterExists(routeName) {
  router.reloadRoutes(false)

  if (routeName === undefined) {
    return false
  }
  // 在你的组件或 JavaScript 代码中
  const routes = router.getRoutes()
  return routes.some((route) => route.name === routeName)
}

function getRouteNameByPath(path) {
  const route = router.getRoutes().find((route) => route.path === path)
  return route ? route.name : null
}

router.beforeEach(
  /**
   * 前置路由
   * @param to 即将要进入的目标路由对象
   * @param from 当前导航正要离开的路由
   * @param next next() 继续执行；next(false) 中断导航；next('/') 或 next({ path: '/' }): 跳转到一个不同的地址
   */
  (to, from, next) => {
    // 浏览器是否已经打开过了
    const isOpenedBrowser = sessionStorage.getItem('isOpenedBrowser') === 'true'
    if (isOpenedBrowser) {
      NProgress.start()
    }

    // console.error('from', from)
    // console.error('to', to)

    // 检查路由是否存在 --------------------------------
    if (!checkRouterExists(to.name)) {
      next({ name: '404' })
      return
    }
    // console.log(to)

    console.log(`!!!!!!!!!!!!!!!!!!!!路由守卫, from: ${from.fullPath}, to: ${to.fullPath}`)

    const userStore = useUserStore()
    // 登录状态 -1未登录；0已登录；1登录失效；
    const loginStatus = userStore.getLoginStatus()
    // console.log('>>>>>>>>>>>>>>>', to)

    const nextCb = (page) => {
      const appStore = useAppStore()

      // 动态切换浏览器标题
      try {
        document.title = getBrowserTitle() + ' - ' + to.meta.title[appStore.language]
      } catch {
        document.title = getBrowserTitle()
      }

      if (page === undefined || page === '') {
        // console.error('【ROUTER】 ' + to.name + ' ======================================================')
        appStore.routerName = to.name
        next()
        return
      }

      if ((page + '').startsWith('/')) {
        const name = getRouteNameByPath(page)
        if (name !== null) {
          appStore.routerName = to.name
        } else {
          console.error('router 没有配置name, path=' + page)
        }
        console.log('>>>> next ', page)

        if (router.hasRoute(name)) {
          next({ path: page })
        } else {
          next({ path: '/404' })
        }
      } else {
        console.log('>>>> next ', page)
        if (router.hasRoute(page)) {
          next({ name: page })
        } else {
          next({ path: '/404' })
        }
      }
    }

    if (to.path === '/') {
      // 如果访问的是根目录
      // console.log('如果访问的是根目录'
      const login_page_name = import.meta.env.VITE_ROUTE_NAME_LOGIN
      if (loginStatus === -1) {
        if (login_page_name && login_page_name !== to.name) {
          // 跳转到登录页面
          nextCb(login_page_name)
        } else {
          nextCb()
        }
      } else {
        // 访问首页，但是没有指定首页的具体页面
        // 取首页的router name
        let homePageName
        if (import.meta.env.VITE_IS_SERVER_ROUTE === 'true') {
          homePageName = userStore.homePage
        } else {
          homePageName = import.meta.env.VITE_ROUTE_NAME_HOME
        }

        // 跳转到登录成功后的第一个页面
        if (homePageName && homePageName === to.name) {
          // 继续跳转
          nextCb()
        } else {
          if (router.hasRoute(homePageName)) {
            // 跳转到首页
            nextCb(homePageName)
          } else {
            // 继续访问根路径
            nextCb()
          }
        }
      }
    } else if (!to.meta || !to.meta.needLogin) {
      // 不需要登录, 直接访问页面
      nextCb()
    } else if (loginStatus === -1) {
      // 未登录
      if (isOpenedBrowser) {
        console.log('未登录，不能访问该页面')
        ElMessageBox.alert(i18n.global.t('app.needLogin'), '', {
          showClose: false,
          confirmButtonText: i18n.global.t('com.btnOk'),
          callback: () => {
            useUserStore().logout()
          },
        })
      } else {
        useUserStore().logout()
      }
    } else if (loginStatus === 1) {
      // 登录失效
      if (isOpenedBrowser) {
        ElMessageBox.alert(i18n.global.t('app.sessionTimeout'), '', {
          showClose: false,
          confirmButtonText: i18n.global.t('com.btnOk'),
          callback: () => {
            useUserStore().logout()
          },
        })
      } else {
        useUserStore().logout()
      }
    } else {
      // 已登录, 判断角色权限
      if (to.roles && to.roles.length > 0) {
        // 需要角色权限
        if (to.roles.some((item) => userStore.roles.includes(item))) {
          // 可以访问
          nextCb()
        } else {
          // 无权限访问
          nextCb('/noPermission')
        }
      } else {
        nextCb()
      }
    }
  }
)

// if (import.meta.env.MODE === 'development') {
//   /** 尝试加载页面对应的国际化文本 */
//   const tryLoadI18n = (i18nJs) => {
//     // console.error(i18nJs)
//     const i18nFileLoad = i18nJs
//     if (!i18nFileLoad) {
//       return // 没有找到翻译文件
//     }
//
//     i18nFileLoad()
//       .then((messagesModule) => {
//         const newTranslations = messagesModule.default
//
//         // 遍历每种语言并合并翻译
//         Object.keys(newTranslations).forEach((lang) => {
//           // 获取现有的翻译（如果存在）
//           const existingTranslations = i18n.global.messages[lang] || {}
//
//           // console.log(i18nJs, existingTranslations)
//           // 合并现有的翻译与新的翻译，并设置到 i18n 实例中
//           i18n.global.setLocaleMessage(lang, { ...existingTranslations, ...newTranslations[lang] })
//         })
//       })
//       .catch((error) => {})
//   }
//
//   Object.entries(comps).forEach(([key, value]) => {
//     if (key.endsWith('i18n.js')) {
//       tryLoadI18n(value)
//     }
//   })
// }

router.afterEach(() => {
  const isOpenedBrowser = sessionStorage.getItem('isOpenedBrowser') === 'true'

  sessionStorage.setItem('isOpenedBrowser', 'true')

  const appStore = useAppStore()
  const router_name = router.currentRoute.value.name

  // 如果跳转的是登录页面, 清空用户信息
  if (router_name === import.meta.env.VITE_ROUTE_NAME_LOGIN) {
    useUserStore().logout()
  }

  // 添加已打开的页面Tab
  if (
    router_name !== import.meta.env.VITE_ROUTE_NAME_HOME &&
    router_name !== 'refresh' &&
    !router.currentRoute.value.children &&
    !constantRoutes.some((item) => {
      return item.name === router_name
    })
  ) {
    // 检查是否已经存在相同name的tab页
    if (!appStore.openedTabs.some((item) => item.name === router_name)) {
      // 获取当前路由信息
      const r = router.currentRoute.value
      console.log('r', r)
      // 如果路由name未定义或为空则返回
      if (r.name === undefined || r.name === '') {
        return
      }

      // 添加新的tab页到openedTabs数组
      appStore.openedTabs.push({
        name: r.name, // 路由名称
        title: r.meta.title, // tab页标题
      })
    }

    // 更新已存在tab页的url
    const existingTab = appStore.openedTabs.find((item) => item.name === router_name)
    if (existingTab) {
      existingTab.url = router.currentRoute.value.fullPath
      // console.error(`更新已存在tab-${existingTab.name}的url => ${existingTab.url}`, appStore.openedTabs)
    }
  }

  NProgress.done()
  document.getElementById('loading').style.display = 'none'
})

// 刷新当前页面
router.refreshCurPage = () => {
  const curPath = router.currentRoute.value.fullPath
  router.push({ path: '/refresh', query: { redirect: curPath } })
  useAppStore().tryHideRouteRedDot(curPath)
}

export default router
