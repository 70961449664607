import axios from 'axios'
import { useUserStore } from '@/stores/user'
import { ElMessage, ElMessageBox } from 'element-plus'
import i18n from '@/i18n'
import { useAppStore } from '@/stores/app'
import { getApiBaseUrl } from '@/utils/env'
import * as Sentry from '@sentry/vue'

// 创建并配置axios实例
const http = axios.create({
  baseURL: getApiBaseUrl(), // 设置API的基本URL
  timeout: 45000, // 请求超时时间（毫秒）
  headers: {
    'Content-Type': 'application/json', // 设置默认的Content-Type
  },
  /** 给服务器发送请求时，是否需要携带Cookie信息.*/
  withCredentials: false,
})

// console.error(getApiBaseUrl())

// 请求拦截器
http.interceptors.request.use(
  (config) => {
    const useStore = useUserStore()
    const appStore = useAppStore()
    config.headers['token'] = useStore._jwt
    config.headers['language'] = appStore.language
    if (config.data && typeof config.data === 'object') {
      // console.log(config.data)
      config.data = removeNullValues(config.data)
      // console.log(config.data)
      if (config.data.search_params) {
        if (config.data.search_params.page) {
          delete config.data.search_params.page
        }
        if (config.data.search_params.page_size) {
          delete config.data.search_params.page_size
        }
      }
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 删除对象中值为 null 的字段
function removeNullValues(obj) {
  if (Array.isArray(obj)) {
    return obj.map((item) => removeNullValues(item))
  } else if (obj !== null && typeof obj === 'object') {
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([_, value]) => {
          if (value === undefined || value === null) {
            return false
          }
          if (typeof value === 'number') {
            return !isNaN(value)
          }
          return true
        })
        .map(([key, value]) => [key, removeNullValues(value)])
    )
  }
  return obj
}

const ignoredAPIs = ['/api/admin/user/keepOnline', '/stat/top']

// 响应拦截器
http.interceptors.response.use(
  (response) => {
    const useStore = useUserStore()
    const isIgnoredAPI = ignoredAPIs.includes(response.config.url)

    if (import.meta.env.DEV || useStore.isDev) {
      if (!isIgnoredAPI) {
        console.info(
          `[${response.config.method.toUpperCase()}][${response.status}]${response.config.url}`,
          response.config.data,
          response.data
        )
      }
    }

    if (!isIgnoredAPI && response.status === 200) {
      useStore.setJwt(response.headers['token'])
    }
    return response.data
  },
  (error) => {
    Sentry.withScope((scope) => {
      // 添加请求相关信息
      scope.setExtra('request_url', error.config?.url)
      scope.setExtra('request_method', error.config?.method)
      scope.setExtra('request_headers', error.config?.headers)
      scope.setExtra('request_data', error.config?.data)

      // 添加响应信息
      scope.setExtra('response_status', error.response?.status)
      scope.setExtra('response_data', error.response?.data)

      scope.setExtra('error_stack', error.stack)

      scope.setTag('error_type', 'api_error')
      Sentry.captureException(error)
    })

    return Promise.reject(error)
  }
)

/**
 * 发送GET请求
 * @param url 请求地址 /user/login
 * @param params 请求参数
 * @param displayTip 请求成功和失败是否显示提示
 * @param tipType 请求失败时的样式，在displayTip为true时生效。 1对话框形式, 2顶部信息提示
 * @param displayLoginTip
 * @param timeout 请求超时时间, 默认15秒
 * @param onProgress 请求进度
 * @param signal AbortSignal 实例
 * @returns {Promise<unknown>}
 */
export const http_get = (
  url,
  params = {},
  displayTip = false,
  tipType = 1,
  displayLoginTip = true,
  timeout = 15000,
  onProgress = undefined,
  signal = undefined
) => {
  tipType = displayTip ? tipType : 0

  return new Promise((resolve, reject) => {
    const makeRequest = () => {
      http
        .get(url, {
          params,
          timeout,
          onDownloadProgress: onProgress ? onProgress : undefined,
          signal: signal,
        })
        .then((response) => {
          successCb(url, response, reject, resolve, tipType, displayLoginTip)
        })
        .catch((error) => {
          networkErrorCb(url, tipType, reject)
        })
    }

    makeRequest()
  })
}

/**
 * 发送POST请求
 * @param url 请求地址 /user/login
 * @param data 提交数据
 * @param displayTip 请求成功和失败是否显示提示
 * @param tipType 请求失败时的样式，在displayTip为true时生效。 1对话框形式, 2顶部信息提示
 * @param displayLoginTip 显示需要登录的提示
 * @param timeout 请求超时时间, 默认15秒
 * @param onProgress 请求进度
 * @param signal AbortSignal 实例
 * @returns {Promise<unknown>}
 */
export const http_post = (
  url,
  data = {},
  displayTip = false,
  tipType = 1,
  displayLoginTip = true,
  timeout = 15000,
  onProgress = undefined,
  signal = undefined
) => {
  tipType = displayTip ? tipType : 0

  return new Promise((resolve, reject) => {
    http
      .post(url, data, {
        timeout,
        onUploadProgress: onProgress ? onProgress : undefined,
        signal: signal,
      })
      .then((response) => {
        successCb(url, response, reject, resolve, tipType, displayLoginTip)
      })
      .catch((error) => {
        console.error(error)
        networkErrorCb(url, tipType, reject)

        // if (error.response) {
        //   send_fs_server_err('请求中间件服务器失败', url, error.response.status, error.response.data, null)
        // } else if (error.request) {
        //   // send_fs_server_err('请求中间件服务器超时', url, 0, null, null)
        // } else {
        //   send_fs_server_err('请求中间件服务器时出现异常', url, 0, null, error.message)
        // }
      })
  })
}

const isTipShowing = {}
let showingNeedLoginUrl = ''

// 成功回调函数
const successCb = (url, response, reject, resolve, tipType, displayLoginTip) => {
  let { status, msg, data } = response
  data = data ? data : {}
  const need_login = status === -1 || status === -2 || status === -3

  /**
   * 通用状态码的的提示消息
   */
  if (status === 0) {
    msg = i18n.global.t('com.successTip')
  } else if (status === -1) {
    msg = i18n.global.t('app.needLogin')
  } else if (status === -2) {
    msg = i18n.global.t('app.loginOtherDevice')
  } else if (status === -3) {
    msg = i18n.global.t('app.sessionTimeout')
  } else if (status === -102) {
    msg = i18n.global.t('app.requestFail') // 数据库插入失败
  } else if (status === -103) {
    msg = i18n.global.t('app.requestFail') // 数据库清空失败
  } else if (status === -105) {
    msg = i18n.global.t('app.requestFail') // 数据库查询失败
  } else if (status === -108) {
    msg = i18n.global.t('app.requestFail') // 数据库更新失败
  } else if (status === -109) {
    msg = i18n.global.t('app.requestFail') // 数据库删除失败
  } else if (status === -110) {
    msg = i18n.global.t('app.requestFail') // 数据库插入或更新失败
  }

  /**
   * 执行回调函数
   */
  const invoke_callback = () => {
    if (status === 0) {
      resolve(data)
    } else {
      reject({ status, msg, data })
    }
  }

  // 对话框
  if (tipType === 1 || (need_login && displayLoginTip)) {
    if (isTipShowing[url]) {
      invoke_callback()
      return
    }

    if (need_login && showingNeedLoginUrl !== '') {
      // invoke_callback()
      return
    }

    if (need_login) {
      showingNeedLoginUrl = url
    } else {
      invoke_callback()
      isTipShowing[url] = true
    }

    ElMessageBox.alert(msg, '', {
      showClose: false,
      type: status === 0 ? 'success' : 'error',
      confirmButtonText: i18n.global.t('com.btnOk'),
      callback: () => {
        isTipShowing[url] = false
        if (showingNeedLoginUrl === url) {
          showingNeedLoginUrl = ''
        }
        if (need_login) {
          useUserStore().logout(false)
        }
      },
    })

    return
  }

  // 顶部提示
  if (tipType === 2) {
    invoke_callback()
    if (need_login) {
      useUserStore().logout(false)
    }

    ElMessage({
      message: msg,
      type: status === 0 ? 'success' : 'error',
    })
    return
  }

  // 无提示
  invoke_callback()
}

/**
 * 是否已显示请求超时的提示框
 */
let isShowingNetworkErr = false

// 网络错误回调函数
const networkErrorCb = (url, tipType, reject) => {
  if (import.meta.env.DEV && tipType !== 0) {
    console.error(`请求超时 ${url}`)
  }

  const msg = i18n.global.t('app.requestFail')

  if (!isShowingNetworkErr && tipType === 1) {
    isShowingNetworkErr = true
    ElMessageBox.alert(msg, '', {
      type: 'error',
      showClose: false,
      confirmButtonText: i18n.global.t('com.btnOk'),
      callback: () => {
        isShowingNetworkErr = false
      },
    })
  } else if (tipType === 2) {
    ElMessage({
      message: msg,
      type: 'error',
    })
  }

  // 调用失败回调
  reject({
    status: 99999,
    msg: i18n.global.t('app.requestFail'),
    data: {},
  })
}
